import { render, staticRenderFns } from "./schoolcontentfour.vue?vue&type=template&id=24701dfd&scoped=true"
import script from "./schoolcontentfour.vue?vue&type=script&lang=js"
export * from "./schoolcontentfour.vue?vue&type=script&lang=js"
import style0 from "./schoolcontentfour.vue?vue&type=style&index=0&id=24701dfd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24701dfd",
  null
  
)

export default component.exports